import { getHttpClient } from '@asgard/ui-common';
import { APP_PAGES, getPagePath } from './app-pages';

export const httpClient = getHttpClient({
  timeout: 10000,
  unauthorizedCallback: () => {
    window.location.replace(
      `${window.location.origin}/${getPagePath(APP_PAGES.logout)}`
    );
  }
});
